import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { ReactNode } from "react";
interface CustomConfirmDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onConfirm: () => void;
  title?: string;
  text?: string;
  children?: ReactNode;
  variant: 'OK' | 'OK|CANCEL'
}
const CustomConfirmDialog: React.FC<CustomConfirmDialogProps> = (
  props: CustomConfirmDialogProps
) => {

  const handleClose = (event: object, reason: string) => {
    if(reason === 'backdropClick') return;
    props.setOpen(false);
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {props.title && (
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      )}
      <DialogContent>
        {props.text && (
          <DialogContentText id="alert-dialog-description">
            {props.text}
          </DialogContentText>
        )}
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onConfirm} autoFocus>
          OK
        </Button>
        {props.variant === 'OK|CANCEL' && <Button onClick={() => props.setOpen && props.setOpen(false)}>Avbryt</Button>}
      </DialogActions>
    </Dialog>
  );
};
export default CustomConfirmDialog;
