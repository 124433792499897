import React, { useEffect, useState } from "react";
import {
  AllNodesInput,
  InputComment,
  Tags,
  Verification,
} from "../../../interfaces/interfaces";
import { Alert, List, MenuItem, Select, TextField } from "@mui/material";
import WarningTitle from "./CounterDeviationTitle";
import { Control, Controller, useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setComment, setTag } from "../../../redux/counterSlice";
import { AppDispatch } from "../../../redux/store";
import CounterDeviationMessage from "./CounterDeviationMessage";
interface CounterDeviationFormProps {
  warning: Verification;
  index: number;
  displayInputs: boolean;
}

const CounterDeviationForm: React.FC<CounterDeviationFormProps> = (
  props: CounterDeviationFormProps
) => {
  const [selectedTag, setSelectedTag] = useState<string>("OTHER");
  const methods = useFormContext<AllNodesInput>();
  const dispatch = useDispatch<AppDispatch>();
  const watchedTag = methods.watch(
    `deviations.counter-${props.warning.counterId}.input-0.tag`
  );
  const watchedComment = methods.watch(
    `deviations.counter-${props.warning.counterId}.input-0.comment`
  );
  useEffect(() => {
    if(!props.displayInputs) return;
    if (watchedTag === undefined) return;
    if (watchedTag) setSelectedTag(watchedTag);
    dispatch(
      setTag({ key: props.warning.counterId.toString(), value: watchedTag })
    );
  }, [watchedTag]);

  useEffect(() => {
    if(!props.displayInputs) return;
    if (watchedComment === undefined) return;
    dispatch(
      setComment({
        key: props.warning.counterId.toString(),
        value: watchedComment,
      })
    );
  }, [watchedComment]);

  useEffect(() => {
    methods.setValue(
      `deviations.counter-${props.warning.counterId}.input-0.comment`,
      "",
      { shouldValidate: true }
    );
    methods.setValue(
      `deviations.counter-${props.warning.counterId}.input-0.tag`,
      "OTHER",
      { shouldValidate: true }
    );
    methods.setValue(
      `deviations.counter-${props.warning.counterId}.input-0.id`,
      props.warning.counterId,
      { shouldValidate: true }
    );
  }, [props.index, props.warning, methods.setValue]);

  return (
    <div>
      <CounterDeviationMessage warning={props.warning} />
      {props.displayInputs === true && (
        <div>
          <Controller
            name={`deviations.counter-${props.warning.counterId}.input-0.comment`}
            key={`${props.warning.counterId}.comment`}
            control={methods.control}
            defaultValue=""
            render={(field) => (
              <TextField
                {...field.field}
                label="Kommentar"
                variant="outlined"
                fullWidth
                data-counterid={props.warning.counterId}
                multiline={true}
                style={{ marginTop: "8px" }}
              />
            )}
          />
          <Controller
            name={`deviations.counter-${props.warning.counterId}.input-0.tag`}
            control={methods.control}
            defaultValue=""
            key={`${props.warning.counterId}.tag`}
            render={(field) => (
              <Select
                {...field.field}
                label="Tag"
                variant="outlined"
                data-counterid={props.warning.counterId}
                fullWidth
                style={{ marginTop: "8px", marginBottom: "8px" }}
              >
                <MenuItem value={"OTHER"}>Avvikelse</MenuItem>
                <MenuItem value={"RESET"}>Mätarbyte/Runtslagning</MenuItem>
              </Select>
            )}
          />
          {selectedTag && selectedTag === "RESET" ? (
            <Alert severity="warning">
              Vid mätarbyte - kom ihåg att ange den nya mätarens nummer!
            </Alert>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default CounterDeviationForm;
