import React, {useEffect, useState} from 'react'
import ActivateAccount from './ActivateAccount'
import {useNavigate, useParams} from 'react-router'
import { isNullOrWhiteSpace } from '../../utils/utils'
import NotFound from '../error/NotFound'
import {useDispatch, useSelector} from 'react-redux'
import { AppDispatch } from '../../redux/store'
import {getPendingUserReset, resolvePendingUserActivation, resolvePendingUserReset} from '../../redux/authSlice'
import {RootState } from '../../redux/store'
import {SubmitHandler, useForm} from 'react-hook-form'
import {AccountResetForm} from './interfaces'
const ActivateAccountContainer = () => {
  const {id} = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const pendingUserReset = useSelector((state: RootState) => state.auth.pendingUserReset)
  const resolvedUserReset = useSelector((state: RootState) => state.auth.resolvedUserReset)
  const [attempts, setAttempts] = useState<number>(0)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  // Get the query parameter token
  const token : string | null = new URLSearchParams(window.location.search).get('token')
  const navigate = useNavigate()
  useEffect(() => {
    if (id && id.length > 0 && token && token.length > 0) {
      dispatch(getPendingUserReset({ id: id, token: token}))
    }
  }, [id])

  useEffect(() => {
    if(pendingUserReset) {
      if(pendingUserReset.userReset?.resolved) {
        navigate('/login')
      }
      if(pendingUserReset.errorMessage && pendingUserReset.isLoading === false) {
        setErrorMessage(pendingUserReset.errorMessage)
      }
    }
  }, [pendingUserReset])

  useEffect(() => {
    if(resolvedUserReset !== undefined) {
      if(resolvedUserReset.userReset?.resolved) {
        navigate('/login')
      }
      else if(resolvedUserReset.errorMessage && resolvedUserReset.isLoading === false) {
        setErrorMessage(resolvedUserReset.errorMessage)
      }
    }
  }, [resolvedUserReset])

  const onSubmit: SubmitHandler<AccountResetForm> = async (data) => {
    setErrorMessage('')
    if (id && token !== null) {
      if(data.newPassword === data.repeatNewPassword) {
        setIsDisabled(true)
        if(pendingUserReset.userReset?.required) {
          dispatch(resolvePendingUserActivation({id, newPassword: data.newPassword, resetToken: token}))
        }
        else {
          dispatch(resolvePendingUserReset({id, newPassword: data.newPassword, resetToken: token}))
        }
        setTimeout(() => {
          setIsDisabled(false)
        }, attempts * 1000)
      }
      else {
        setErrorMessage('Lösenorden matchar inte.')
      }
    }
  }
  if (isNullOrWhiteSpace(id)) return <NotFound />
  return (
    pendingUserReset && (
      <ActivateAccount onSubmit={onSubmit} isReset={!pendingUserReset.userReset?.required} isDisabled={isDisabled} pendingUserReset={pendingUserReset.userReset} isLoading={pendingUserReset.isLoading} errorMessage={errorMessage} />
    )
  )
}
export default ActivateAccountContainer