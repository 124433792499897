import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Collapse,
  TextField,
  Button,
  Box,
  Alert,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { ExpandMore, ExpandLess, CheckCircle } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import {
  addPendingReadingToCart,
  clearCart,
  fetchEstate,
} from "../../redux/estateSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { Controller, set, SubmitHandler, useForm } from "react-hook-form";
import {
  Electricity,
  ElectricityHeat,
  CoolingEnergy,
  CoolingFlow,
  HeatingEnergy,
  HeatingFlow,
  Time,
  Oil,
  Temperature,
  Water,
  Efficiency,
} from "../../icons/gomorron";
import {
  Domain,
  House,
  PinDrop,
  Speed,
  ChevronRight,
  Check,
  Recycling,
} from "@mui/icons-material";
import {
  clearPendingReadings,
  getCounterValueExists,
  getCounterWarnings,
  saveCounterValue,
} from "../../redux/counterSlice";
import {
  CartItem,
  Estate,
  IFormInput,
  PendingReading,
  Reading,
  Verification,
} from "../../interfaces/interfaces";
import { ReadingType } from "../../enums/ReadingType";
import { PayloadAction } from "@reduxjs/toolkit";
import EstateViewCounterTreeIcon from "./EstateViewCounterTreeIcon";
import EstateViewMeasurePointTreeIcon from "./EstateViewMeasurePointTreeIcon";
import ControlledNumericTextField from "../ControlledNumericTextField";
import CustomConfirmDialog from "../warnings/Deviation/CustomConfirmDialog";
const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    marginTop: "2rem",
  },
  logo: {
    width: "150px",
    marginBottom: "1rem",
  },
  listGroup: {
    width: "100%",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
  },
  multiValueWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  errorAir: {
    borderColor: "red",
  },
  childListItem: {
    paddingLeft: "1rem",
    borderLeft: "1px solid #ddd",
    marginBottom: "0.5rem",
  },
  grandChildListItem: {
    paddingLeft: "0.5rem",
    paddingBottom: "0.5rem",
    paddingRight: "0.5rem",
    border: "1px solid #ddd",
    marginBottom: "0.5rem",
  },
  errorTextField: {
    backgroundColor: "#ffcccc",
  },
  warningTextField: {
    backgroundColor: "#ffb26e",
  },
});

const EstateView: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { estateId } = useParams<{ estateId: string }>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const cart = useSelector((state: RootState) => state.estates.cart);
  const [airValues, setAirValues] = useState<{ [key: string]: number }>({});
  const [efficiency, setEfficiency] = useState<{ [key: string]: string }>({});
  const [didInitialFetch, setDidInitialFetch] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [shouldShowUnsavedChangesModal, setShouldShowUnsavedChangesModal] =
    useState<boolean>(false);
  const [
    shouldShowUnsavedChangesNotification,
    setShouldShowUnsavedChangesNotification,
  ] = useState<boolean>(false);
  const [unsavedCounters, setUnsavedCounters] = useState<number[]>([]);
  const { register, handleSubmit, control } = useForm<IFormInput>();
  const saveCounterValueResult = useSelector(
    (state: RootState) => state.counter.saveCounterValue
  );
  const pendingEstateId = useSelector(
    (state: RootState) => state.estates.pendingEstateId
  );
  const estate = useSelector(
    (state: RootState) => state.estates.fetchEstate.data
  );
  useEffect(() => {
    dispatch(clearPendingReadings());
  }, [dispatch]);

  useEffect(() => {
    if (estateId !== undefined && estateId !== null && Number(estateId) > 0) {
      dispatch(fetchEstate(Number(estateId)));
    } else {
      setDidInitialFetch(true);
    }
  }, [dispatch, estateId]);

  useEffect(() => {
    if(pendingEstateId && estateId !== undefined && estateId !== null && Number(estateId) > 0) {
      console.log(pendingEstateId, Number(estateId));    
      setShouldShowUnsavedChangesModal(pendingEstateId === Number(estateId));
    }
    else {    
      setShouldShowUnsavedChangesModal(false);
    }
  }, [pendingEstateId, estateId]);

  useEffect(() => {
    if (estateId !== undefined && estateId !== null && Number(estateId) > 0) {
      const itemsForEstate = cart.data.filter(
        (x: CartItem) => x.estateId === Number(estateId)
      );
      setShouldShowUnsavedChangesNotification(itemsForEstate.length > 0);
      const unsavedReadings = itemsForEstate.map((x) => x.readings).flat();
      setUnsavedCounters(unsavedReadings.map((x) => x.id));
    } else {
      setShouldShowUnsavedChangesNotification(false);
      setUnsavedCounters([]);
    }
  }, [estateId, cart]);

  useEffect(() => {
    const calculateEfficiency = () => {
      const newEfficiency: { [key: string]: string } = {};
      let shouldDisableSubmit = false;

      Object.keys(airValues).forEach((key) => {
        const id = parseInt(key.split("_").pop() || "");

        const fromAir = airValues[`FROMAIR_${id}`] || 0;
        const offAir = airValues[`OFFAIR_${id}`] || 0;
        const outAir = airValues[`OUTAIR_${id}`] || 0;

        if (fromAir && offAir && outAir) {
          let efficiencyValue = ((fromAir - offAir) / (fromAir - outAir)) * 100;
          if (isNaN(efficiencyValue) || !isFinite(efficiencyValue)) {
            efficiencyValue = 0;
          }
          newEfficiency[id] = `${efficiencyValue.toFixed(2)}%`;

          if (fromAir - outAir === 0) {
            shouldDisableSubmit = true;
          }
        }
      });

      setEfficiency(newEfficiency);
      setDisableSubmit(shouldDisableSubmit);
    };

    calculateEfficiency();
  }, [airValues]);

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    // Helper function to add readings to the cart
    const addReadingsToCart = (readings: Reading[], hasWarning: boolean) => {
      if (estate) {
        readings.forEach((reading) => {
          dispatch(
            addPendingReadingToCart({
              estateId: estate.id,
              reading: {
                id: reading.id,
                values: reading.values,
                type: reading.type,
                override: reading.override,
                warnings: [],
              },
              hasWarning,
            })
          );
        });
      }
    };

    // Helper function to process form data into readings
    const processFormData = (formData: IFormInput): Reading[] => {
      const readings: Reading[] = [];
      Object.keys(formData).forEach((key) => {
        const id = parseInt(key.split("_").pop() || "");
        const valueType = key.split("_")[0];
        const input = formData[key];

        if (input) {
          const existing = readings.find((item) => item.id === id);
          if (existing) {
            existing.values[valueType] = input;
          } else {
            readings.push({
              id,
              values: { [valueType]: input },
              type:
                valueType === "val" ? ReadingType.default : ReadingType.heating,
            });
          }
        }
      });
      return readings;
    };

    // Clear the cart before processing new readings
    dispatch(clearCart());

    // Process form data into readings
    const readingsToSave = processFormData(data);

    // Check for warnings
    const warningsAction: any = await dispatch(
      getCounterWarnings(readingsToSave)
    );
    const hasWarnings =
      warningsAction.meta.requestStatus === "fulfilled" &&
      warningsAction.payload.some((pendingReading: PendingReading) =>
        pendingReading.warnings.some((warning: Verification) => warning.warning)
      );

    // Check for existing readings
    const existsAction: any = await dispatch(
      getCounterValueExists(readingsToSave)
    );
    const exists =
      existsAction.meta.requestStatus === "fulfilled" &&
      existsAction.payload.some((item: any) => item.alreadyExists);

    // Handle warnings or existing readings
    if (hasWarnings || exists) {
      addReadingsToCart(readingsToSave, true);
      navigate(`/warnings/${estate?.id}`);
    } else {
      addReadingsToCart(readingsToSave, false);
      const saveAction = await dispatch(saveCounterValue(readingsToSave));
      if (saveAction.meta.requestStatus === "fulfilled") {
        navigate("/result");
      }
    }
  };

  const handleAirValueChange = (id: number, field: string, value: number) => {
    setAirValues((prevValues) => ({
      ...prevValues,
      [`${field}_${id}`]: value,
    }));
  };

  const isReadingPending = (counterId: number) =>
    unsavedCounters.some((unsavedCounterId) => unsavedCounterId === counterId);

  return (
    <div>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        {error && <Alert severity="error">{error}</Alert>}
        {shouldShowUnsavedChangesNotification && (
          <Alert severity="error">
            Det finns mätare vars avläsning ej slutfördes. Vänligen kontrollera
            avlästa mätare i listan.
          </Alert>
        )}
        {estate ? (
          <Box sx={{ maxHeight: "60vh", overflowY: "auto", minWidth: "200px" }}>
            <SimpleTreeView>
              {estate.addresses.map((address) => (
                <TreeItem
                  key={`address-${address.id}`}
                  itemId={`address-${address.id}`}
                  label={
                    <div className={classes.listItem}>
                      <Typography>{address.name}</Typography>
                      {address.allMeasurePointsHaveReadings && (
                        <CheckCircle color="success" />
                      )}
                    </div>
                  }
                >
                  {address.measurePoints.map((measurePoint) => (
                    <TreeItem
                      key={`measurepoint-${measurePoint.id}`}
                      itemId={`measurepoint-${measurePoint.id}`}
                      label={
                        <div
                          className={`${classes.listItem} ${classes.childListItem}`}
                        >
                          <EstateViewMeasurePointTreeIcon
                            measurePoint={measurePoint}
                            defaultValue=""
                          />
                          <Typography>
                            {measurePoint.name ||
                              measurePoint.measurePointNumbers.join(", ")}{" "}
                            {measurePoint.type ? `(${measurePoint.type})` : ""}
                          </Typography>
                          {measurePoint.allCountersHaveReadings && (
                            <CheckCircle color="success" />
                          )}
                        </div>
                      }
                    >
                      {measurePoint.counters.map((counter) => (
                        <TreeItem
                          key={`counter-${counter.id}`}
                          itemId={`counter-${counter.id}`}
                          label={
                            <div
                              className={`${classes.listItem} ${
                                classes.grandChildListItem
                              } ${
                                isReadingPending(counter.id)
                                  ? classes.errorTextField
                                  : ""
                              }`}
                            >
                              <div className={classes.multiValueWrapper}>
                                <div
                                  style={{
                                    padding: "0.1rem",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <EstateViewCounterTreeIcon
                                    counter={counter}
                                    defaultValue=""
                                  />
                                  <Typography component={"span"}>
                                    {counter.name.length > 0 ||
                                    counter.counterNumbers.length > 0 ? (
                                      <div>
                                        {counter.name} (
                                        {counter.counterNumbers.join(", ")})
                                      </div>
                                    ) : (
                                      <div>SAVEUP-ID: {counter.id}</div>
                                    )}
                                  </Typography>
                                </div>
                                {counter.isHeatExchanger ? (
                                  <div className={classes.multiValueWrapper}>
                                    <ControlledNumericTextField
                                      name="FROMAIR"
                                      nodeId={counter.id}
                                      control={control}
                                      label="Från-luft"
                                      className="reading-number multi-value-input from-air"
                                      handleAirValueChange={
                                        handleAirValueChange
                                      }
                                    />
                                    <ControlledNumericTextField
                                      name="OFFAIR"
                                      nodeId={counter.id}
                                      control={control}
                                      label="Av-luft"
                                      className="reading-number multi-value-input off-air"
                                      handleAirValueChange={
                                        handleAirValueChange
                                      }
                                    />
                                    <ControlledNumericTextField
                                      name="OUTAIR"
                                      nodeId={counter.id}
                                      control={control}
                                      label="Ute-luft"
                                      className={`reading-number multi-value-input out-air ${
                                        airValues[`OUTAIR_${counter.id}`] > 5
                                          ? classes.warningTextField
                                          : ""
                                      }`}
                                      handleAirValueChange={
                                        handleAirValueChange
                                      }
                                    />
                                    <TextField
                                      label="Verkningsgrad"
                                      type="text"
                                      className={`reading-number ${
                                        airValues[`FROMAIR_${counter.id}`] -
                                          airValues[`OUTAIR_${counter.id}`] ===
                                        0
                                          ? classes.errorTextField
                                          : ""
                                      }`}
                                      style={{ maxWidth: 110 }}
                                      name={`efficiency_${counter.id}`}
                                      id={`efficiency_${counter.id}`}
                                      value={efficiency[counter.id] || "0%"}
                                      InputProps={{ readOnly: true }}
                                      onClick={(e) => e.stopPropagation()}
                                      onFocus={(e) => e.stopPropagation()}
                                      onKeyDown={(e) => e.stopPropagation()}
                                      data-type="efficiency"
                                      data-id={counter.id}
                                    />
                                    <input
                                      type="hidden"
                                      id={`isHeatExchanger_${counter.id}`}
                                      name={`isHeatExchanger_${counter.id}`}
                                      value={counter.isHeatExchanger.toString()}
                                    />
                                  </div>
                                ) : (
                                  <ControlledNumericTextField
                                    name=""
                                    nodeId={counter.id}
                                    control={control}
                                    label=""
                                    className="form-control reading-number"
                                  />
                                )}
                                <div style={{ padding: "0.1rem" }}>
                                  {counter.lastReading > 0 && (
                                    <Typography
                                      fontSize={"small"}
                                      variant="subtitle2"
                                    >
                                      Senast: {counter.lastReading}
                                      {counter.lastReadingDate &&
                                        ` ${new Date(
                                          counter.lastReadingDate
                                        ).toLocaleString()}`}
                                      {counter.hasReadingThisPeriod && (
                                        <CheckCircle color="success" />
                                      )}
                                    </Typography>
                                  )}
                                  {counter.estimatedReading > 0 && (
                                    <Typography
                                      fontSize={"small"}
                                      variant="subtitle2"
                                    >
                                      Beräknat: {counter.estimatedReading}
                                    </Typography>
                                  )}
                                  {airValues[`OUTAIR_${counter.id}`] > 5 ? (
                                    <div style={{ marginTop: "8px" }}>
                                      <Alert severity="warning">
                                        Vid avläsning vid högre utetemperaturer
                                        än 5 grader blir verkningsgraden
                                        opålitlig. Försök göra mätningen vid en
                                        lägre utetemperatur om möjligt.
                                      </Alert>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          }
                        />
                      ))}
                    </TreeItem>
                  ))}
                </TreeItem>
              ))}
            </SimpleTreeView>
          </Box>
        ) : didInitialFetch ? (
          <Alert severity="warning">
            Ingen fastighet vald. Tryck på tillbaka för att återgå.
          </Alert>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <CircularProgress size={100} />
          </div>
        )}
        {estate && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="button-primary"
            fullWidth
            disabled={loading || disableSubmit}
          >
            Spara
          </Button>
        )}
        <Button
          variant="contained"
          className="button-secondary"
          fullWidth
          onClick={() => navigate("/estates")}
        >
          Tillbaka
        </Button>
      </form>
      <CustomConfirmDialog
        variant="OK"
        open={shouldShowUnsavedChangesModal}
        onConfirm={() => setShouldShowUnsavedChangesModal(false)}
        setOpen={() => setShouldShowUnsavedChangesModal(false)}
        title="Varning"
        text="Den senaste avläsningen slutfördes ej för denna fastighet. Vänligen kontrollera avlästa mätare i listan."
      />
    </div>
  );
};

export default EstateView;
