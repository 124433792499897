import { ReadingType } from "../enums/ReadingType";
import { ResultType } from "../enums/ResultType";

export enum Role {
  Readers = 0,
  Customer = 1,
  Controllers = 2,
  Administrators = 3,
}
export interface Auth {
  id: number;
  isLoggedIn: boolean;
  refreshToken: string | null;
  expires: string;
  role: string;
  token: string | null;
  userName: string;
  isAAD?: boolean;
  permissions?: string[];
}
export interface User {
  id: number;
  fullName: string;
  mail: string;
  userRoleId: number;
  isDisabled: boolean;
  role: string;
  permissions: string[];
}
export interface Me {
  id: number;
  fullName: string;
  mail: string;
  role: string;
  permissions: string[];
}
export interface Login {
  username: string;
  password: string;
}

export interface LoginState {
  auth: Auth | null;
  status: number | undefined;
  isLoading: boolean;
  errorMessage: string;
}
export interface Estate {
  id: number;
  ownerId: number;
  name: string;
  description: string;
  updDate: Date;
  insDate: Date;
  hidden: boolean;
  addresses: Address[];
}
export interface Address {
  id: number;
  estateId: number;
  name: string;
  street: string;
  postalCode: string;
  city: string;
  number: string;
  comment: string;
  hidden: boolean;
  updDate: Date;
  insDate: Date;
  measurePoints: MeasurePoint[];
  allMeasurePointsHaveReadings: boolean;
}
export interface MeasurePoint {
  id: number;
  addressId: number;
  name: string;
  include: number;
  description: string;
  hidden: boolean;
  updDate: Date;
  insDate: Date;
  number?: string | undefined;
  inactivePeriods?: number[];
  counters: Counter[];
  type: string;
  measurePointNumbers: string[];
  allCountersHaveReadings: boolean;
}
export interface Counter {
  id: number;
  measurePointId: number;
  meterId: number;
  name: string;
  turned: boolean;
  constant: number;
  hidden: boolean;
  insDate: Date;
  updDate: Date;
  counterNumbers: string[];
  hasReadingThisPeriod: boolean;
  lastReading: number;
  lastReadingDate: Date;
  estimatedReading: number;
  isConsumption: boolean;
  isHeatExchanger: boolean;
  tags: Tag[];
}

export interface Tag {
  id: number;
  name: string;
  class: string;
}
export interface CounterResetParameter {
  turned: boolean;
  changed: boolean;
}
export interface CounterValueParameter {
  counterId: number;
  date: string;
  value?: number | null;
  values?: ValuesParameter[] | null;
  override: boolean;
  counterReset?: CounterResetParameter;
}
export interface ValuesParameter {
  value: number;
  type: string;
}
export interface Reading {
  id: number;
  values: { [key: string]: number };
  type: ReadingType;
  override?: boolean;
  turned?: boolean;
  changed?: boolean;
}
export interface PendingReading extends Reading {
  warnings: Verification[];
  comment?: string;
  tag?: string;
}
export interface Result<T> {
  type: ResultType;
  ResultString: string;
  Message: string;
  tag: T | null;
}
export interface CounterValue {
  id: number;
  counterId: number;
  userId: number;
  value: number;
  estimated: boolean;
  reading: boolean;
  date: Date;
  insDate: Date;
  updDate: Date;
  counterValueLogs?: CounterValueLog[];
}
export interface CounterVerificationParameter {
  counterReadings: ReadingVerificationParameter[];
  date: string;
}
export interface ReadingVerificationParameter {
  counterId: number;
  value: number;
}
export interface Verification {
  warning: boolean;
  message: string;
  counterNumbers: string[];
  counterId: number;
  counterName: string;
  counterType: string;
  counterUnit: string;
  isDecreasing: boolean;
  isConsumption: boolean;
  counterMeterType: string;
  value: number;
  previousValue: number;
  consumption: number;
  lastYearsConsumption: number;
  difference: number;
  deviation: number;
  min: number;
  max: number;
}
export interface AllNodesInput {
  deviations: {
    [key: string]: {
      [key: string]: {
        tag: string;
        comment: string;
        id: number;
      };
    };
  };
}
export interface Tags {
  [key: string]: string;
}
export interface InputTag {
  key: string;
  value: string;
}
export interface InputComment {
  key: string;
  value: string;
}
export interface CounterValueLogParameter {
  counterValueId: number;
  text: string;
  tag: string;
}
export interface CounterValueLog {
  id: number;
  counterValueId: number;
  text: string;
  updDate: Date;
  insDate: Date;
}
export interface IFormInput {
  [key: string]: any;
}
export interface SendUserResetState {
  result: UserPasswordResetResult | undefined;
  isLoading: boolean;
  errorMessage: string;
  lastMail: Date | undefined;
}
export interface UserPasswordResetResult {
  userId: number;
  responseMessage: string;
}
export interface Cart {
  isLoading: boolean;
  errorMessage: string;
  data: CartItem[];
}
export interface CartItem {
  estateId: number;
  readings: CartReading[];
}
export interface CartReading extends PendingReading
{
  hasWarning: boolean;
  warningMessage: string | undefined;
  hasError: boolean; 
  errorMessage: string | undefined
}