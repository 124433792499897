import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
interface PasswordResetDialogProps {
    open: boolean
    setOpen: (value: boolean) => void
    onConfirm: () => void
}
const LoginPasswordResetDialog : React.FC<PasswordResetDialogProps> = (props: PasswordResetDialogProps) => {
    return (
        <Dialog open={props.open} onClose={() => props.setOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Återställ lösenord'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Detta kommer skicka ut ett återställningsmail till din registerade adress. Vill du fortsätta?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onConfirm} autoFocus>
            OK
          </Button>
          <Button onClick={() => props.setOpen(false)}>Avbryt</Button>
        </DialogActions>
      </Dialog>
    )
}
export default LoginPasswordResetDialog