// counterSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import saveupService from "../api/saveupService";
import {
  CounterResetParameter,
  CounterValue,
  CounterValueLogParameter,
  CounterValueParameter,
  CounterVerificationParameter, 
  InputComment, 
  InputTag, 
  PendingReading, 
  ReadingVerificationParameter,
  Result, 
  ValuesParameter,
  Verification,
} from "../interfaces/interfaces";
import { handleResponse } from "../api/saveupClient";
import { Reading } from "../interfaces/interfaces";
import { ReadingType } from "../enums/ReadingType";
import { ResultType } from "../enums/ResultType";
import { LOG_ERROR, OK, PENDING, VALUE_ERROR } from "../shared/constants";
import { createCounterValueParameter, handleSaveResponse } from "../helpers/counterValueHelper";

interface CounterState {
  saveCounterValue: SaveCounterValueState;
  getCounterValueExists: GetCounterValueExistsState;
  getCounterWarnings: GetCounterWarningsState;
  loading: boolean;
  error: string | null;
  errors: any[];
  exists: boolean;
  hasWarning: boolean;
  pendingReadings: PendingReading[],
  savedReadings: CounterValue[],
  failedReadings: CounterValue[],
  tags: InputTag[]
  comments: InputComment[]
  results: Result<CounterValue>[]
}

interface SaveCounterValueState {
  results: Result<CounterValue>[];
  loading: boolean;
  error: string | null;
}

interface GetCounterValueExistsState {
  alreadyExistingValues: { counterId: number; alreadyExists: boolean }[];
  loading: boolean;
  error: string | null;
}

interface GetCounterWarningsState {
  loading: boolean;
  error: string | null;
  hasWarnings: boolean
}

const savedCounterValuesState: SaveCounterValueState = {
  results: [],
  loading: false,
  error: null,
};

const getCounterValueExistsState: GetCounterValueExistsState = {
  alreadyExistingValues: [],
  loading: false,
  error: null,
};

const getCounterWarningsState: GetCounterWarningsState = {
  loading: false,
  error: null,
  hasWarnings: false
};

const initialState: CounterState = {
  saveCounterValue: savedCounterValuesState,
  getCounterValueExists: getCounterValueExistsState,
  getCounterWarnings: getCounterWarningsState,
  loading: false,
  error: null,
  errors: [],
  exists: false,
  hasWarning: false,
  pendingReadings: [],
  savedReadings: [],
  failedReadings: [],
  tags: [],
  comments: [],
  results: [],
};

export const getCounterWarnings = createAsyncThunk(
  "counter/getCounterWarnings",
  async (readings: Reading[], { rejectWithValue }) => {
    let warnings: Verification[] = [];
    try {
      let readingsToVerify: ReadingVerificationParameter[] = [];
      const currentDateString = new Date().toISOString();
      for (let i = 0; i < readings.length; i++) {
        const reading = readings[i];

        let value = 0;
        if (reading.type === ReadingType.default) {
          value = reading.values["val"];
        } else if (reading.type === ReadingType.heating) {
          if (reading.values["FROMAIR"] - reading.values["OUTAIR"] === 0) {
            return rejectWithValue("Felaktiga värden för Från-Luft och Ute-Luft!");
          }
          value = ((reading.values["FROMAIR"] - reading.values["OFFAIR"]) / (reading.values["FROMAIR"] - reading.values["OUTAIR"])) * 100;
        }
        readingsToVerify.push({ counterId: reading.id, value: value });
      }


      const counterVerificationParameter = {
        counterReadings: readingsToVerify,
        date: currentDateString,
      };
      warnings = await saveupService.getCounterValueWarnings(counterVerificationParameter);    
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
    const pendingReadings : PendingReading[] = readings.map((reading : Reading) => {
      const warningsForReading = warnings.filter((warning: Verification) => warning.counterId === reading.id);
      return {...reading, warnings: warningsForReading};
    });
    return pendingReadings;
  }
);

export const getCounterValueExists = createAsyncThunk(
  "counter/getCounterValueExists",
  async (readings: Reading[] | PendingReading[], { rejectWithValue }) => {
    let alreadyExistingValues: { counterId: number; alreadyExists: boolean }[] =
      [];
    try {
      const currentDateString = new Date().toISOString();
      for (let i = 0; i < readings.length; i++) {
        const reading = readings[i];
        if (reading.override) continue;
        const response = await saveupService.getCounterValueExists(
          reading.id,
          currentDateString
        );
        if (response.status == 200) {
          alreadyExistingValues.push({
            counterId: reading.id,
            alreadyExists: response.data,
          });
        } else {
          return rejectWithValue(response.statusText);
        }
      }
      return alreadyExistingValues;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const setTag = createAsyncThunk("counter/setTag", async ({ key, value }: { key: string; value: string }, { rejectWithValue })=> {
  return { key, value };
});

export const setComment = createAsyncThunk("counter/setComment", async ({ key, value }: { key: string; value: string }, { rejectWithValue}) => {
  return { key, value };
});


export const saveCounterValue = createAsyncThunk(
  "counter/saveCounterValue",
  async (readings: Reading[] | PendingReading[], { rejectWithValue }) => {
    const counterValueSaveActionResults: Result<CounterValue>[] = [];

    for (const reading of readings) {
      const counterValueParameter = createCounterValueParameter(reading);

      try {
        if (counterValueParameter.value || counterValueParameter.values) {
          const response =
            reading.type === ReadingType.heating
              ? await saveupService.saveCounterValues(counterValueParameter)
              : await saveupService.saveCounterValue(counterValueParameter);
          const result = await handleSaveResponse(response, reading);
          counterValueSaveActionResults.push(result);
        } else {
          counterValueSaveActionResults.push({
            type: ResultType.Error,
            ResultString: VALUE_ERROR,
            Message: "Felaktiga värden.",
            tag: null,
          });
        }
      } catch (error: any) {
        counterValueSaveActionResults.push({
          type: ResultType.Error,
          ResultString: error.message,
          Message: error.message,
          tag: null,
        });
      }
    }

    return counterValueSaveActionResults;
  }
);

const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    clearPendingReadings(state) {
      state.pendingReadings = []
    },
    clearAllSetTags(state) {
      state.tags = []
    },
    clearAllSetComments(state) {
      state.comments = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCounterValue.pending, (state) => {
        state.saveCounterValue.loading = true;
        state.saveCounterValue.error = null;
        state.saveCounterValue.results = [];
      })
      .addCase(
        saveCounterValue.fulfilled,
        (state, action: PayloadAction<Result<CounterValue>[]>) => {
          state.saveCounterValue.loading = false;
          state.saveCounterValue.results = [...action.payload];
        }
      )
      .addCase(
        saveCounterValue.rejected,
        (state, action: PayloadAction<any>) => {
          state.saveCounterValue.loading = false;
          state.saveCounterValue.results = [];
          state.saveCounterValue.error = "Unknown error";
        }
      )
      .addCase(getCounterValueExists.pending, (state) => {
        state.getCounterValueExists.loading = true;
        state.getCounterValueExists.error = null;
        state.getCounterValueExists.alreadyExistingValues = [];
      })
      .addCase(
        getCounterValueExists.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.getCounterValueExists.loading = false;
          state.getCounterValueExists.alreadyExistingValues = action.payload;
        }
      )
      .addCase(
        getCounterValueExists.rejected,
        (state, action: PayloadAction<any>) => {
          state.getCounterValueExists.loading = false;
          state.getCounterValueExists.error = action.payload;
        }
      )
      .addCase(getCounterWarnings.pending, (state) => {
        state.getCounterWarnings.loading = true;
        state.getCounterWarnings.error = null;
      })
      .addCase(
        getCounterWarnings.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.getCounterWarnings.loading = false;  
          const hasWarnings = action.payload.some((pendingReading: PendingReading) => pendingReading.warnings.length > 0);
          state.hasWarning = hasWarnings;
          state.pendingReadings = action.payload;
        }
      )
      .addCase(
        getCounterWarnings.rejected,
        (state, action: PayloadAction<any>) => {
          state.getCounterWarnings.loading = false;
          state.getCounterWarnings.error = action.payload;
        }
      )
      .addCase(
        setTag.fulfilled,
        (state, action: PayloadAction<any>) => {
          if(action.payload.value !== undefined) {
            let tag = state.tags.find(x => x.key === action.payload.key)
            if(tag !== undefined) {
              tag.value = action.payload.value;
              state.tags = [...state.tags.filter(x => tag && x.key !== tag.key), tag]
            }
            else {
              state.tags = [...state.tags, { key: action.payload.key, value: action.payload.value }]
            }
          }
        }
      )
      .addCase(
        setComment.fulfilled,
        (state, action: PayloadAction<any>) => {
          if(action.payload.value !== undefined) {
            let comment = state.comments.find(x => x.key === action.payload.key)
            if(comment !== undefined) {
              comment.value = action.payload.value;
              state.comments = [...state.comments.filter(x => comment && x.key !== comment.key), comment]
            }
            else {
              state.comments = [...state.comments, { key: action.payload.key, value: action.payload.value }]
            }
          }
        }
      )
  },
});
export const { clearPendingReadings, clearAllSetTags, clearAllSetComments } = counterSlice.actions;
export default counterSlice.reducer;
