import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  clearAllSetComments,
  clearAllSetTags,
  saveCounterValue,
} from "../../redux/counterSlice";
import {
  Button,
  Typography,
  Alert,
  Box,
  Modal,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RootState, AppDispatch } from "../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import {
  AllNodesInput,
  Counter,
  InputTag,
  PendingReading,
  Verification,
} from "../../interfaces/interfaces";
import CounterDeviations from "./Deviation/CountersDeviations";
import { getAllCounters } from "../../helpers/estateHelper";
import AlreadyExistsMessage from "./AlreadyExistsMessage";
import CounterDeviationTitle from "./Deviation/CounterDeviationTitle";
import { clearCart, setEstatePending, unsetEstatePending } from "../../redux/estateSlice";
const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    marginTop: "2rem",
  },
  logo: {
    width: "150px",
    marginBottom: "1rem",
  },
  listGroup: {
    width: "100%",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  multiValueWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  errorAir: {
    borderColor: "red",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  spinner: {
    outline: "none",
  },
});

const SaveCounterValue: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const { estateId } = useParams<{ estateId: string }>();
  const { loading, error, exists } = useSelector(
    (state: RootState) => state.counter
  );
  const navigate = useNavigate();
  const methods = useForm<AllNodesInput>({
    defaultValues: {
      deviations: {},
    },
  });
  const [countersWithDeviations, setCountersWithDeviations] = useState<
    number[]
  >([]);
  const [
    countersWithAlreadyExistingValues,
    setCountersWithAlreadyExistingValues,
  ] = useState<number[]>([]);
  const [isInputValid, setIsInputValid] = useState<boolean>(false);
  const [warningsWereFound, setWarningsWereFound] = useState<boolean>(false);
  const [allCommentsEmpty, setAllCommentsEmpty] = useState<boolean>(true);
  const tags = useSelector((state: RootState) => state.counter.tags);
  const comments = useSelector((state: RootState) => state.counter.comments);
  const saveResult = useSelector(
    (state: RootState) => state.counter.saveCounterValue
  );
  useEffect(() => {
    dispatch(clearAllSetTags());
    dispatch(clearAllSetComments());
    dispatch(unsetEstatePending());
  }, []);

  useEffect(() => {
    if (estateId) {
      !allCommentsEmpty
        ? dispatch(setEstatePending(Number(estateId)))
        : dispatch(unsetEstatePending());
    }
  }, [estateId, allCommentsEmpty]);

  useEffect(() => {
    const hasInvalidTag =
      tags &&
      tags.some(
        (t: InputTag) => t.value === undefined || t.value.trim() === ""
      );
    const hasInvalidComment =
      comments &&
      comments.some(
        (c: InputTag) =>
          c.value === undefined || c.value.trim() === "" || c.value.length === 0
      );
    setIsInputValid(!hasInvalidComment && !hasInvalidTag);
  }, [tags, comments]);

  const pendingReadings = useSelector(
    (state: RootState) => state.counter.pendingReadings
  );
  const getCounterValueExistsResult = useSelector(
    (state: RootState) => state.counter.getCounterValueExists
  );

  useEffect(() => {
    let deviationCounterIds: number[] = [];
    let alreadyExistingCounterIds: number[] = [];
    let anyWarningFound = false;
    for (let i = 0; i < pendingReadings.length; i++) {
      if (!countersWithDeviations.find((uq) => uq === pendingReadings[i].id)) {
        if (pendingReadings[i].warnings.some((v: Verification) => v.warning)) {
          deviationCounterIds.push(pendingReadings[i].id);
          anyWarningFound = true;
        }
      }
      if (
        !countersWithAlreadyExistingValues.find(
          (uq) => uq === pendingReadings[i].id
        )
      ) {
        if (getCounterValueExistsResult.alreadyExistingValues) {
          const exists =
            getCounterValueExistsResult.alreadyExistingValues.filter(
              (x: { counterId: number; alreadyExists: boolean }) =>
                x.counterId === pendingReadings[i].id && x.alreadyExists
            );
          if (exists.length > 0) {
            alreadyExistingCounterIds.push(pendingReadings[i].id);
          }
        }
      }
    }
    setWarningsWereFound(anyWarningFound);
    setCountersWithDeviations(deviationCounterIds);
    setCountersWithAlreadyExistingValues(alreadyExistingCounterIds);
  }, [pendingReadings]);

  const onSubmit: SubmitHandler<AllNodesInput> = async (data) => {
    let valuesToSave = pendingReadings.map((pendingReading: PendingReading) => {
      let deviation = data.deviations[`counter-${pendingReading.id}`];
      let input = deviation ? deviation["input-0"] : undefined;
      if (input && input.id === pendingReading.id) {
        return {
          ...pendingReading,
          comment: input.comment,
          tag: input.tag,
          override: true,
        };
      } else return { ...pendingReading, override: true };
    });
    const result = await dispatch(saveCounterValue(valuesToSave));
    if (result.meta.requestStatus === "fulfilled") {    
      dispatch(unsetEstatePending());
      navigate("/result");
    }
  };

  const onFormChanged: React.FormEventHandler<HTMLFormElement> | undefined = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    const data = new FormData(event.currentTarget);
    const keys = Array.from(data.keys()).filter((key) =>
      key.includes("comment")
    );
    const valuesByKeys = keys.map((key) => data.get(key));    
    setAllCommentsEmpty(valuesByKeys.every((value) => value === ""));
  };

  return (
    <div>
      <Typography variant="h5" className="estate-header">
        {sessionStorage.getItem("estateName")}
      </Typography>
      <FormProvider {...methods}>
        <form
          className={classes.form}
          onSubmit={methods.handleSubmit(onSubmit)}
          onChange={onFormChanged}
        >
          {error && <Alert severity="error">{error}</Alert>}
          <div style={{ height: "auto", overflowY: "scroll" }}>
            {pendingReadings.map((pendingReading: PendingReading) => {
              const hasDeviations = countersWithDeviations.includes(
                pendingReading.id
              );
              const hasExistingValues =
                countersWithAlreadyExistingValues.includes(pendingReading.id);

              return (
                <div key={pendingReading.id}>
                  {hasDeviations || hasExistingValues ? (
                    <CounterDeviationTitle
                      counterName={pendingReading.warnings[0].counterName}
                      counterNumbers={pendingReading.warnings[0].counterNumbers}
                      counterType={pendingReading.warnings[0].counterType}
                      counterId={pendingReading.warnings[0].counterId}
                    />
                  ) : (
                    ""
                  )}
                  {hasDeviations && (
                    <CounterDeviations
                      counterId={pendingReading.id}
                      index={countersWithDeviations.indexOf(pendingReading.id)}
                      pendingReading={pendingReadings.find(
                        (x) => x.id === pendingReading.id
                      )}
                    />
                  )}
                  {hasExistingValues && (
                    <AlreadyExistsMessage counterId={pendingReading.id} />
                  )}
                </div>
              );
            })}
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="button-primary"
            fullWidth
            disabled={loading || (warningsWereFound && !isInputValid)}
          >
            Fortsätt
          </Button>
          <Button
            variant="contained"
            className="button-secondary"
            fullWidth
            onClick={() => {              
              if(allCommentsEmpty) {
                // If all comments are empty when the user returns, clear the checkout cart.
                dispatch(clearCart());
              }
              navigate(`/estate/${estateId}`)
            }}
          >
            Tillbaka
          </Button>
        </form>
      </FormProvider>
      <Modal open={saveResult.loading} className={classes.modal}>
        <CircularProgress className={classes.spinner} />
      </Modal>
    </div>
  );
};

export default SaveCounterValue;
