import React, {useEffect, useState} from 'react'
import {AccountResetForm, ActivateAccountProps} from './interfaces'
import {Alert, Button, CircularProgress, Grid, IconButton, Stack, TextField, Typography} from '@mui/material'
import LoginContainer from '../login/LoginContainer'
import { LoginBox, ImageLogo } from '../login/LoginContainer'
import {Close} from '@mui/icons-material'
import {useForm} from 'react-hook-form'
const ActivateAccount = (props: ActivateAccountProps) => {
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const methods = useForm<AccountResetForm>({defaultValues: {oldPassword: '', repeatNewPassword: '', newPassword: ''}})
  useEffect(() => {
    if (props.errorMessage && props.errorMessage.length > 0) setShowAlert(true)
    return () => {
      setShowAlert(false)
    }
  }, [props.errorMessage])

  // If still loading, just show a centered spinnder.
  if (props.isLoading)
    return (
      <div style={{textAlign: 'center'}}>
        <CircularProgress />
      </div>
    )

  return (
    <LoginContainer maxWidth='xs'>
      <Grid>
        <Grid container direction='row' spacing={1}>
          <LoginBox>
            <Stack direction='row' spacing={2}>
              <ImageLogo>
                <img src="../img/saveup.logo.svg" alt="SaveUp Logo" className="logo" />
              </ImageLogo>
            </Stack>
            {props.pendingUserReset && props.pendingUserReset.resolved === false ? (
              props.pendingUserReset.resolved === false ? (
                <div>
                  <Typography variant='h6' component='h6' gutterBottom>
                    {props.isReset ? 'Ange nytt lösenord för att slutföra lösenordsåterställningen.' : 'Ange önskat lösenord för att aktivera ditt konto.'}
                  </Typography>
                  <form onSubmit={methods.handleSubmit(props.onSubmit)}>
                    {/* {props.isReset && <TextField margin='normal' required fullWidth label='Lösenord' type='password' {...methods.register('oldPassword', {required: true})} />} */}
                    <TextField margin='normal' required fullWidth label='Nytt lösenord' type='password' {...methods.register('newPassword', {required: true})} />
                    <TextField margin='normal' required fullWidth label='Repetera lösenord' type='password' {...methods.register('repeatNewPassword', {required: true})} />
                    <Button type='submit' fullWidth variant='contained' sx={{mt: 3, mb: 2}} disabled={props.isDisabled} style={{backgroundColor: '#00d170'}}>
                      Aktivera konto
                    </Button>
                  </form>
                </div>
              ) : (
                <div>
                  <Typography variant='h5' component='h5' gutterBottom>
                    Ditt konto är redan aktiverat.
                  </Typography>
                </div>
              )
            ) : (
              ''
            )}
          </LoginBox>
        </Grid>
        <Grid container direction='row' spacing={1}>
          <Grid item xs={12}>
            {showAlert && props.errorMessage ? (
              <Alert
                severity='error'
                action={
                  <IconButton
                    aria-label='close'
                    color='inherit'
                    size='small'
                    onClick={() => {
                      setShowAlert(false)
                    }}>
                    <Close fontSize='inherit' />
                  </IconButton>
                }
                sx={{mb: 2, width: '300px'}}>
                {props.errorMessage}
              </Alert>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Grid>
    </LoginContainer>
  )
}
export default ActivateAccount
