import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
interface LoginPasswordResetProgressDialogProps {
    open: boolean
    text: string
    setOpen: (value: boolean) => void
}
const LoginPasswordResetProgressDialog : React.FC<LoginPasswordResetProgressDialogProps> = (props: LoginPasswordResetProgressDialogProps) => {
    return (
        <Dialog open={props.open} onClose={() => props.setOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Återställ lösenord'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.setOpen(false)} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
}
export default LoginPasswordResetProgressDialog