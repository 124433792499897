import React from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import store from "./redux/store";
import LoginForm from "./components/login/LoginForm";
import Estates from "./components/estates/Estates";
import EstateView from "./components/estates/EstateView";
import PrivateRoutes from "./components/PrivateRoutes";
import WarningPage from "./components/warnings/WarningPage";
import background from "./img/background.jpg";
import Layout from "./components/Layout";
import SaveResultPage from "./components/result/SaveResultPage";
import ActivateAccountContainer from "./components/activate/ActiveAccountContainer";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="*"
      element={
        <Layout>
          <Routes>
            <Route path="/" element={<LoginForm />} />
            <Route path="/login" element={<LoginForm />} />
            <Route element={<PrivateRoutes />}>
              <Route path="/estates" element={<Estates />} />
              <Route path="/estate/:estateId" element={<EstateView />} />
              <Route path="/warnings/:estateId" element={<WarningPage />} />
              <Route path="/result" element={<SaveResultPage />} />
            </Route>
            <Route
              path="/activate/:id"
              element={<ActivateAccountContainer />}
            />
          </Routes>
        </Layout>
      }
    />
  )
);

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
};

export default App;
