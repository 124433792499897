import React, { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { setAccessToken } from "../../redux/authSlice";
import { Cloud, Key } from "@mui/icons-material";
import { Button } from "@mui/material";
import { loginRequest, tokenRequest } from "../../msalConfig";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { AppDispatch } from "../../redux/store";
import { getAuthToken } from "../../helpers/authHelper";

export interface AADLoginButtonProps {
  loading: boolean;
  onLogin: (value: boolean) => void;
}

const AADLoginButton: React.FC<AADLoginButtonProps> = ({ loading, onLogin }) => {
  const { instance, } = useMsal();

  const handleLogin = async () => {
    try {
      onLogin(true);
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      onLogin(false);
      console.error("Login failed: ", error);
    }
  };

  return (
    <Button
      variant="contained"
      fullWidth
      disabled={loading}
      className="button-cloud"
      style={{ backgroundColor: "#007FFF", color: "white", fontWeight: "bold" }}
      onClick={handleLogin}
    >
      <Key />
      &nbsp;Logga in
    </Button>
  );
};

export default AADLoginButton;