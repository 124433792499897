import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  setUserProfile,
  login,
  sendCurrentUserResetMail,
} from "../../redux/authSlice";
import { TextField, Button, Alert, Box, Link, Typography } from "@mui/material";
import { RootState, AppDispatch } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import "../../style/custom.css"; // Import the custom CSS file
import AADLoginButton from "./AADLoginButton";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../../msalConfig";
import { InteractionStatus } from "@azure/msal-browser";
import { Auth } from "../../interfaces/interfaces";
import jwtDecode from "jwt-decode";
import { Close } from "@mui/icons-material";
import LoginProgress from "./LoginProgress";
import { isNullOrWhiteSpace, isValidEmail } from "../../utils/utils";
import LoginPasswordResetDialog from "./LoginPasswordResetDialog";
import LoginPasswordResetProgressDialog from "./LoginPasswordResetProgressDialog";

interface IFormInput {
  username: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();

  const { loading, error } = useSelector((state: RootState) => state.auth);
  const { register, handleSubmit, watch } = useForm<IFormInput>();

  const currentUser = useSelector((state: RootState) => state.auth.me);
  const [showLegacyLogin, setShowLegacyLogin] = React.useState(false);

  const [isLoggingIn, setIsLoggingIn] = React.useState<boolean>(false);

  const currentUserName = watch("username", "");

  const [promptText, setPromptText] = useState<string>(
    "Ett återställningsmail har skickats till din registrerade adress."
  );

  const [passwordResetDialogOpen, setPasswordResetDialogOpen] =
    useState<boolean>(false);
  const [passwordResetProgressDialogOpen, setPasswordResetProgressDialogOpen] =
    useState<boolean>(false);

  const lastReset = useSelector((state: RootState) => state.auth.reset);

  useEffect(() => {
    if (
      !isAuthenticated ||
      inProgress !== InteractionStatus.None ||
      accounts?.length === 0
    )
      return;

    const initializeToken = async () => {
      setIsLoggingIn(true);
      let accessToken: string | null = null;
      try {
        const tokenResponse = await instance.handleRedirectPromise();

        if (tokenResponse) {
          instance.setActiveAccount(tokenResponse.account);
          accessToken = tokenResponse.accessToken;
        }
      } catch (error) {
        console.log("Error handling token redirect.", error);
      }

      if (!accessToken) {
        try {
          const response = await instance.acquireTokenSilent(tokenRequest);
          accessToken = response.accessToken;
        } catch (error) {
          console.error("Silent token acquisition failed: ", error);
        }

        if (!accessToken) {
          try {
            await instance.acquireTokenRedirect(tokenRequest);
          } catch (error) {
            console.error("Redirect token acquisition failed.", error);
          }
        }
      }
      if (isAuthenticated && accessToken) {
        const decoded: any = jwtDecode(accessToken);
        dispatch(
          setUserProfile({ isAAD: true, expiresDate: new Date(decoded.exp * 1000) })
        );
      }
    };

    initializeToken();
  }, [isAuthenticated, inProgress, instance, dispatch]);

  useEffect(() => {
    const auth: Auth = localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth") as string)
      : null;
    if (auth?.isAAD && isAuthenticated && currentUser) {
      setIsLoggingIn(false);
      navigate("/estates");
    }
    if (!auth?.isAAD && currentUser) {
      setIsLoggingIn(false);
      navigate("/estates");
    }
  }, [isAuthenticated, currentUser]);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setIsLoggingIn(false);
    const loginResponse = await dispatch(login(data));
    if (loginResponse.meta.requestStatus === "fulfilled") {
      if (!loginResponse.payload.isAAD && loginResponse.payload.token) {
        navigate("/estates");
      }
    }
  };

  const onPasswordResetClick = () => {
    if (isNullOrWhiteSpace(currentUserName)) {
      setPromptText(
        "E-postadress saknas. Vänligen fyll i e-postadress i fältet för användarnamn och försök igen."
      );
      setPasswordResetProgressDialogOpen(true);
      return;
    } else if (!isValidEmail(currentUserName)) {
      setPromptText(
        "Användarnamnet är inte en giltig e-postadress. Vänligen fyll i en giltig e-postadress och försök igen."
      );
      setPasswordResetProgressDialogOpen(true);
      return;
    }
    setPasswordResetDialogOpen(true);
  };

  const onPasswordResetConfirm = () => {
    setPasswordResetDialogOpen(false);

    let tenMinutesAgo = new Date();
    tenMinutesAgo.setMinutes(tenMinutesAgo.getMinutes() - 10);

    if (lastReset && lastReset.lastMail && lastReset.lastMail > tenMinutesAgo) {
      setPromptText(
        "En lösenordsåterställning har redan skickats. Vänligen vänta innnan du försöker igen."
      );
      setPasswordResetProgressDialogOpen(true);
    } else {
      setPromptText(
        "Ett återställningsmail har skickats till din registrerade adress."
      );
      setPasswordResetProgressDialogOpen(true);
      dispatch(sendCurrentUserResetMail(currentUserName));
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <Box display="flex" justifyContent="center" className="image-logo">
          <img src="img/saveup.logo.svg" alt="SaveUp Logo" className="logo" />
        </Box>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          {isLoggingIn && <LoginProgress isLoggingIn={isLoggingIn} />}
          <AADLoginButton
            loading={loading}
            onLogin={(value: boolean) => setIsLoggingIn(value)}
          />
          {showLegacyLogin && (
            <>
              <TextField
                label="Användarnamn"
                variant="outlined"
                fullWidth
                {...register("username", { required: true })}
              />
              <TextField
                label="Lösenord"
                variant="outlined"
                type="password"
                fullWidth
                {...register("password", { required: true })}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading}
                className="button-primary"
              >
                Logga in med extern användare
              </Button>
            </>
          )}
          <Button
            variant="text"
            onClick={() => setShowLegacyLogin(!showLegacyLogin)}
          >
            {showLegacyLogin ? <Close /> : "Logga in med extern användare"}
          </Button>
          {error && <Alert severity="error">{error}</Alert>}
        </form>
      </div>
      <LoginPasswordResetDialog
        setOpen={setPasswordResetDialogOpen}
        open={passwordResetDialogOpen}
        onConfirm={onPasswordResetConfirm}
      />
      <LoginPasswordResetProgressDialog
        setOpen={setPasswordResetProgressDialogOpen}
        open={passwordResetProgressDialogOpen}
        text={promptText}
      />
      {showLegacyLogin && (
        <Box display="flex" justifyContent="flex-start" width="100%">
          <Typography variant="caption">
            <Link href="#" onClick={onPasswordResetClick} underline="none">
              Återställ lösenord
            </Link>
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default LoginForm;
