export function getDistinctBy<T>(arr: T[], prop: keyof T): T[] {
  const seen = new Set<T[keyof T]>();
  return arr.reduce((acc, item) => {
    if (!seen.has(item[prop])) {
      seen.add(item[prop]);
      acc.push(item);
    }
    return acc;
  }, [] as T[]);
}
export function isNullOrWhiteSpace(str: string | null | undefined) {
  return str === undefined || str === null || str.trim().length === 0
}
export function isValidEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
