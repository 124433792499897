import { styled } from '@mui/material/styles';
import { Box, Container } from '@mui/material';

const LoginContainer = styled(Container)(
  ({ theme }) => `
    position: inherit;
    justify-items: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 80vh;
    padding: 20px;

    @media (max-width: 600px) {
      height: 100vh;
      padding: 10px;
    }
  `
);

export const ImageLogo = styled(Box)(
  ({ theme }) => `
    padding-bottom: 50px;

    @media (max-width: 600px) {
      padding-bottom: 20px;
    }
  `
);

export const LoginBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '360px',
});

export default LoginContainer;