import axios, {
  Axios,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import {
  CounterValue,
  CounterValueLogParameter,
  CounterValueParameter,
  CounterVerificationParameter,
  Estate,
  Login,
  Me,
  Result,
  User,
  Verification,
} from "../interfaces/interfaces";
import saveupClient, { handleResponse } from "./saveupClient";
import { UserResetDto } from "../components/activate/interfaces";

async function login(data: Login) {
  return await saveupClient.get<AxiosResponse>(
    `/user/login?username=${data.username}&password=${data.password}`
  );
}
async function getUserEstates() {
  return await saveupClient.get<Estate[]>(`/user/estates`).then(handleResponse);
}
async function getEstate(estateId: number) {
  return await saveupClient
    .get<Estate>(`/estate/${estateId}/Full?all=false`)
    .then(handleResponse);
}
async function getCounterValueExists(counterId: number, dateString: string) {
  return await saveupClient.get<boolean>(
    `/counter/value/exists?counterId=${counterId}&date=${dateString}`
  );
}
async function getCounterValueWarnings(
  counterVerificationParameter: CounterVerificationParameter
) {
  return await saveupClient
    .post<Verification>(`/counter/verify/list`, counterVerificationParameter)
    .then(handleResponse);
}
async function getCurrentUser() {
  return await saveupClient.get<User>(`user/me`).then(handleResponse);
}
async function getUserInfo() {
  return await saveupClient.get<Me>(`user/me`).then(handleResponse);
}
async function getPendingUserReset(id: string, token: string) {
  return await saveupClient
    .get<UserResetDto>(`user/reset/${id}?token=${token}`)
    .then(handleResponse);
}
async function searchEstates(keyword: string) {
  const result = await saveupClient
    .post<Estate[]>(`/estate/search?keyword=${keyword}`)
    .then(handleResponse);
  return result;
}
async function saveCounterValue(counterValueParameter: CounterValueParameter) {
  return await saveupClient.post<AxiosResponse>(
    `/counter/value/save`,
    counterValueParameter
  );
}
async function saveCounterValues(counterValueParameter: CounterValueParameter) {
  return await saveupClient.post<AxiosResponse>(
    `/counter/values/save`,
    counterValueParameter
  );
}
async function saveCounterValueLog(
  counterValueLogParameter: CounterValueLogParameter
) {
  const htmlEncodedString = encodeURIComponent(counterValueLogParameter.text);
  return await saveupClient.post<AxiosResponse>(
    `/counter/value/log?counterValueId=${counterValueLogParameter.counterValueId}&text=${htmlEncodedString}&tag=${counterValueLogParameter.tag}`
  );
}
async function saveUserReset(username: string) {
  const baseUrl: string = process.env.REACT_APP_API_URL || "/api";
  const httpHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const controller = new AbortController();
  const config: AxiosRequestConfig = {
    baseURL: baseUrl,
    headers: httpHeaders,
    timeout: 10000,
    responseType: "json",
    maxContentLength: 50000,
    maxBodyLength: 50000,
    maxRedirects: 5,
    signal: controller.signal,
  };
  const tmpClient: AxiosInstance = axios.create(config);
  return await tmpClient
    .post<string>(`user/current/emailreset`, { email: username })
    .then(handleResponse);
}
async function resolvePendingUserReset(guid: string, newPassword: string, resetToken: string) {
  const baseUrl: string = process.env.REACT_APP_API_URL || "/api";
  const httpHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
  const controller = new AbortController()
  
  const config: AxiosRequestConfig = {
    baseURL: baseUrl,
    headers: httpHeaders,
    timeout: 10000,
    responseType: 'json',
    maxContentLength: 50000,
    maxBodyLength: 50000,
    maxRedirects: 5,
    signal: controller.signal,
  }
  const tmpClient: AxiosInstance = axios.create(config)  
  return await tmpClient.post<UserResetDto>(`user/reset/resolve?token=${resetToken}`, {guid, newPassword}).then(handleResponse)
}
async function resolvePendingUserActivation(guid: string, newPassword: string, activationToken: string) {
  const baseUrl: string = process.env.REACT_APP_API_URL || "/api";
  const httpHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
  const controller = new AbortController()
  
  const config: AxiosRequestConfig = {
    baseURL: baseUrl,
    headers: httpHeaders,
    timeout: 10000,
    responseType: 'json',
    maxContentLength: 50000,
    maxBodyLength: 50000,
    maxRedirects: 5,
    signal: controller.signal,
  }
  const tmpClient: AxiosInstance = axios.create(config)  
  return await tmpClient.post<UserResetDto>(`user/activate/resolve?token=${activationToken}`, {guid, newPassword}).then(handleResponse)
}
const saveupService = {
  login,
  getUserEstates,
  getEstate,
  getCounterValueExists,
  getCounterValueWarnings,
  getCurrentUser,
  getUserInfo,
  getPendingUserReset,
  searchEstates,
  saveCounterValue,
  saveCounterValues,
  saveCounterValueLog,
  saveUserReset,
  resolvePendingUserReset,
  resolvePendingUserActivation
};
export default saveupService;
