import { CircularProgress, Typography } from '@mui/material';
import React from 'react'
interface LoginProgressProps {
    isLoggingIn: boolean;
}

const LoginProgress: React.FC<LoginProgressProps> = ({ isLoggingIn }) => {
    return (
        <div>
        {isLoggingIn ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
                <CircularProgress size={100} />
            </div>
        ) : (
            <div />
        )}
        </div>
    );
}
export default LoginProgress;